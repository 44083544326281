import React from 'react';
import { types } from '../pages';

export const Page = ({ embroiderer }) => {
    const { viewStack } = embroiderer;
    const view = viewStack[viewStack.length - 1];
    const Components = types[view.view];

    /**
     * Subpage view manged by SubConfigure type
     */
    const { viewData } = viewStack.at(-1);
    const SubComponent = Components.SubConfigure ? Components.SubConfigure[viewData.subType] : null;
    // End

    return (
        <Components.State embroiderer={embroiderer} data={view.viewData}>
            {({ embroideryPage }) => (
                SubComponent ? (
                    <SubComponent
                        embroiderer={embroiderer}
                        embroideryPage={embroideryPage}
                    />
                ) : (
                    <Components.Configure
                        embroiderer={embroiderer}
                        embroideryPage={embroideryPage}
                    />
                )
            )}
        </Components.State>
    );
};
