import React from 'react';
import { HeadTag } from '@silkpwa/module/react-component/head-tag';
import { EmbroidererProps } from 'ui/component/embroidery-configurator/add-embroidery-state';
import { Container } from './container';
import { Body } from '../body';

interface IConfiguratorProps {
    embroiderer: EmbroidererProps;
}

export const Configurator: React.FC<IConfiguratorProps> = ({ embroiderer }) => (
    <React.Fragment>
        <Container>
            <Body embroiderer={embroiderer} />
        </Container>
        <HeadTag tag="style">
            {embroiderer.fonts}
        </HeadTag>
    </React.Fragment>
);
